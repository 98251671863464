var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wrapper style1",attrs:{"id":"one"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"6u$ 12u$(medium)"},[_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col points"},[_c('i',{staticClass:"fas fa-solid fa-check"}),_c('h4',[_vm._v("Love it or refund it")]),_vm._m(3),_c('b-tooltip',{attrs:{"target":"refund-tooltip","triggers":"hover"}},[_vm._v(" Please note that only our packs are refundable. For more information check our "),_c('router-link',{attrs:{"target":"_blank","to":{
                name: 'FAQs',
              }}},[_vm._v("FAQs page")])],1)],1)]),_vm._m(4)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('ul',{staticClass:"actions"},[_c('li',[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'About'},"target":"_blank"}},[_vm._v("Know more about me")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"major"},[_c('h2',[_vm._v("Choosing the right teacher is difficult")]),_c('br'),_c('h3',[_vm._v("We make it easy for you...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"6u 12u$(medium)"},[_c('iframe',{attrs:{"width":"560","height":"315","src":"https://www.youtube.com/embed/QF-qG_wimRI?rel=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write;\n          encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col points"},[_c('i',{staticClass:"fas fa-solid fa-check"}),_c('h4',[_vm._v("Free lesson")]),_c('p',[_vm._v("Great opportunity to know us better and to answer all your questions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 30-day refund on your first pack (unused credits) "),_c('span',{attrs:{"id":"refund-tooltip"}},[_c('i',{staticClass:"fa-solid fa-circle-info"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col points"},[_c('i',{staticClass:"fas fa-solid fa-check"}),_c('h4',[_vm._v("Only one-to-one lessons")]),_c('p',[_vm._v("No more crowded lessons. More time for you to practice")])])])
}]

export { render, staticRenderFns }