<template>
  <section id="plans" class="pricing wrapper style1 special">
    <div class="container">
      <header class="major">
        <h2>Plans</h2>
        <switch-button text="🎁 Looking for a gift?" @change="onChange"></switch-button>
      </header>
      <div class="row" v-if="!lookingForGift">
        <!-- Free Tier -->
        <div class="col-lg-4">
          <div class="card mb-lg-0 mb-5">
            <div class="card-body">
              <h3 class="card-title text-center">Free Lesson</h3>
              <div class="text-center italic">One lesson (free)</div>
              <br class="computer">
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>35 min lesson
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>MySpanishNow first activity
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Discussion about your goals, needs and level
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Solving questions and doubts
                </li>
              </ul>
              <div class="button-container">
                <router-link :to="{ name: 'Schedule lesson'}"
                  class="button wide special"
                  target="_blank">Schedule free lesson</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Subscription Tier -->
        <div class="col-lg-4">
          <div class="card">
            <!-- <div class="ribbon ribbon-eligible">FiverFest eligible*</div> -->
            <div class="card-body">
              <h3 class="card-title ribbon-eligible-card-title text-center">Subscription</h3>
              <div class="text-center italic">Minimum of 11 lessons.
                At least one lesson per month</div>
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span><strong>Lessons of 55 min each</strong>
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised material
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Individualised tracking
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Homework (optional)
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Doubts and feedback by email
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Split payment
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>More economical
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Additional lessons for the same price
                </li>
              </ul>
              <router-link
                :to="{ name: 'Subscriptions Payments' }"
                class="button wide"
                target="_blank">From £24.99 per lesson</router-link>
            </div>
          </div>
        </div>
        <!-- Packs Tier -->
        <div class="col-lg-4">
          <div class="card mb-lg-0 mb-5">
            <!-- <div class="ribbon ribbon-eligible">FiverFest eligible*</div> -->
            <div class="card-body">
              <h3 class="card-title ribbon-eligible-card-title text-center">Pack</h3>
              <div class="text-center italic">Packs of 1, 5 and 10 lessons</div>
              <br class="computer">
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span><strong>Lessons of 55 min each</strong>
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised material
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Individualised tracking
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Homework (optional)
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Doubts and feedback by email
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>30-day refund on your first pack (unused credits)
                </li>
              </ul>
              <div class="button-container">
                <router-link :to="{ name: 'Packs Payments'}"
                  class="button wide"
                  target="_blank">From £27 per lesson</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <!-- One Lesson Gift -->
        <div class="col-lg-4">
          <div class="card mb-lg-0 mb-5">
            <!-- <div class="ribbon ribbon-eligible">FiverFest eligible*</div> -->
            <div class="card-body">
              <h3 class="card-title ribbon-eligible-card-title text-center">Pack <i>entusiasta</i></h3>
              <div class="text-center italic">1 lesson</div>
              <br class="computer">
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span><strong>1 lesson of 55 min</strong>
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised gift card
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised material
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Individualised tracking
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Homework (optional)
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Doubts and feedback by email
                </li>
              </ul>
              <div class="button-container">
                <router-link :to="{
                  name: 'Contact',
                  query: {
                    subject: 'Pack entusiasta - 1 lesson as a gift',
                    message: 'Hello, I would like the pack entusiasta of 1 lesson as a gift for ...'
                  },
                }"
                  target="_blank"
                  class="button wide">I want this gift</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Three Lessons Gift -->
        <div class="col-lg-4">
          <div class="card">
            <!-- <div class="ribbon ribbon-eligible">FiverFest eligible*</div> -->
            <div class="card-body">
              <h3 class="card-title ribbon-eligible-card-title text-center">Pack <i>súper entusiasta</i></h3>
              <div class="text-center italic">1 free + 3 lessons</div>
              <br class="computer">
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span><strong>3 lessons of 55 min each</strong>
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>1 free lesson of 35 min
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised gift card
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised material
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Individualised tracking
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Homework (optional)
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Doubts and feedback by email
                </li>
              </ul>
              <router-link :to="{
                name: 'Contact',
                query: {
                  subject: 'Pack súper entusiasta - 3 lessons as a gift',
                  message: 'Hello, I would like the pack súper entusiasta of 3 lessons as a gift for ...'
                },
              }"
                target="_blank"
                class="button wide special">I want this gift</router-link>
            </div>
          </div>
        </div>
        <!-- Five Lessons Gift -->
        <div class="col-lg-4">
          <div class="card">
            <!-- <div class="ribbon ribbon-eligible">Gift card included</div> -->
            <div class="card-body">
              <h3 class="card-title ribbon-eligible-card-title text-center">Pack <i>hiperentusiasta</i></h3>
              <div class="text-center italic">1 free + 5 lessons</div>
              <br class="computer">
              <hr>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span><strong>5 lessons of 55 min each</strong>
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>1 free lesson of 35 min
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised gift card
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Personalised material
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Individualised tracking
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Homework (optional)
                </li>
                <li>
                  <span class="fa-li">
                    <i class="fas fa-check"></i>
                  </span>Doubts and feedback by email
                </li>
              </ul>
              <router-link :to="{
                name: 'Contact',
                query: {
                  subject: 'Pack hiperentusiasta - 5 lessons as a gift',
                  message: 'Hello, I would like the pack hiperentusiasta of 5 lessons as a gift for ...'
                },
              }"
                target="_blank"
                class="button wide">I want this gift</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col">
          <p class="fiverfest-description">*If you choose this plan you will be eligible to an extra 45 minutes lesson for only £5</p>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import SwitchButton from '../utils/SwitchButton.vue';

export default {
  name: 'Plans',
  data() {
    return {
      lookingForGift: false,
    };
  },
  components: {
    SwitchButton,
  },
  methods: {
    onChange(isSelected) {
      this.lookingForGift = isSelected;
    },
  },
};
</script>

<style scoped>
  .fiverfest-description {
    margin-bottom: 0;
    margin-top: 15px;
  }

  .ribbon.ribbon-eligible {
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    background: #f32953;
    border-radius: 0px 0px 5px 5px;
    top: 0;
    color: white;
    font-size: 15px;
    width: 153px;
    text-align: center;
    left: 50%;
    margin-left: -76.5px;
    line-height: 23px;
  }

  .card-title {
    margin-top: 15px;
  }

  .section-bottom-description {
    text-align: left;
    margin: 0;
    margin-top: 15px;
  }

  .button-container {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .card-title {
    color: #f32853;
    margin-bottom: 0;
  }

  .italic {
    font-style: italic;
  }

  .card-body ul {
    text-align: left;
  }

  a.button {
    font-size: 1.2em;
  }

  .card {
    height: 100%;
  }

  a.button {
    padding: 0 0.5em;
  }

  @media screen and (max-width: 992px) {
    .computer {
      display: none;
    }
  }

  @media screen and (max-width: 980px) {
    .row > div {
      margin-top: 25px;
    }

    .row > div:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 736px) {
    header {
      margin-bottom: 0;
    }
  }
</style>
