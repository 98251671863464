<template>
  <div class="switch-container">
    <h4>
      {{ text }}
      <label class="switch">
        <input type="checkbox" @change="onChange($event)">
        <span data-v-abdb31a6="" class="slider round"></span>
      </label>
    </h4>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    },
  },
};
</script>

<style scoped>
  .switch-container {
    margin-bottom: 10px;
  }

  .switch-container > h4 {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: inherit;
  }

  .switch {
    position: relative;
    display: block;
    width: 50px;
    height: 28px;
    margin: 0;
    margin-left: 5px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #f32853;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
