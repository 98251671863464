<template>
  <section id="four" class="wrapper style2 special">
    <div class="container">
      <header class="major">
        <h2>Any Questions?</h2>
      </header>
      <ul class="actions">
        <li><router-link :to="{ name: 'Contact' }" target="_blank"
          class="button special big">Get in touch</router-link></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactMe',
  props: {},
};
</script>

<style scoped>
  header.major {
    margin-bottom: 0;
  }

  @media screen and (max-width: 980px) {
    .actions {
      margin: 0;
    }
  }
</style>
