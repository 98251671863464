<template>
  <section id="banner">
    <banner-image-overlay></banner-image-overlay>
    <h2 class="title">Online Spanish Adapted to You</h2>
    <p>Connect with the Spanish to connect with your family and friends</p>
    <ul class="actions">
      <li>
        <router-link :to="{ name: 'Register' }" target="_blank"
        class="button special big">Register and get a Free Lesson</router-link>
      </li>
    </ul>
  </section>
</template>

<script>
import BannerImageOverlay from '../utils/BannerImageOverlay.vue';

export default {
  name: 'Banner',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  strong, b {
    color: #B6B6B6;
  }

  @media screen and (max-width: 480px) {
    #banner {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }

  @media screen and (max-width: 330px) {
    #banner {
      padding: 4em 1.75em 4em 1.75em;
    }
  }
</style>
