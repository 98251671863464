<template>
  <section id="one" class="wrapper style1">
    <div class="container">
      <header class="major">
        <h2>Choosing the right teacher is difficult</h2><br>
        <h3>We make it easy for you...</h3>
      </header>
      <div class="row">
        <div class="6u 12u$(medium)">
          <iframe width="560" height="315"
            src="https://www.youtube.com/embed/QF-qG_wimRI?rel=0"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="6u$ 12u$(medium)">
          <div class="row">
            <div class="col points">
              <i class="fas fa-solid fa-check"></i>
              <h4>Free lesson</h4>
              <p>Great opportunity to know us better and to answer all your questions</p>
            </div>
          </div>
          <div class="row">
            <div class="col points">
              <i class="fas fa-solid fa-check"></i>
              <h4>Love it or refund it</h4>
              <p>
                30-day refund on your first pack (unused credits)
                <span id="refund-tooltip">
                  <i class="fa-solid fa-circle-info"></i>
                </span>
              </p>
              <b-tooltip target="refund-tooltip" triggers="hover">
                Please note that only our packs are refundable.
                For more information check our
                <router-link target="_blank" :to="{
                  name: 'FAQs',
                }">FAQs page</router-link>
              </b-tooltip>
            </div>
          </div>
          <div class="row">
            <div class="col points">
              <i class="fas fa-solid fa-check"></i>
              <h4>Only one-to-one lessons</h4>
              <p>No more crowded lessons. More time for you to practice</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <ul class="actions">
            <li><router-link :to="{ name: 'About'}" target="_blank"
              class="button">Know more about me</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'IsItForMe',
};
</script>

<style scoped>
  ul {
    padding-left: 2em;
  }

  .major h2 {
    margin-bottom: 0;
  }

  header {
    margin-bottom: 35px;
  }

  iframe {
    max-width: 100%;
    max-height: 100%;
    border-radius: 22px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    box-shadow: 0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07);
  }

  #refund-tooltip {
    cursor: pointer;
  }

  .points h4 {
    display: inline-block;
    margin: 0;
  }

  .points i.fa-check {
    margin-right: 10px;
  }

  .actions {
    margin: 0;
    margin-top: 20px;
  }

  @media screen and (max-width: 980px) {
    .row div:last-child > p:last-child {
      margin: 0;
    }

    .points {
      margin-top: 15px;
    }

    #refund-tooltip {
      font-size: large;
    }
  }
</style>
