<template>
  <section class="reviews wrapper style2 special">
    <div class="container">
      <div class="row">
        <div class="col">
          <vue-tiny-slider
            :responsive="responsive"
            items="2"
            :autoplay="false"
            :rewind="false"
            :loop="true"
            :autoplayHoverPause="true"
            :autoplayButtonOutput="false"
            :controls="true"
            :nav="false"
            :autoHeight="true"
            :mouseDrag="false"
            :controlsText="controlsText">
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    I recommend classes with Maria Angeles at MySpanishNow, the classes are always well prepared, interesting and fun and I definitely feel like my Spanish is progressing - muchisimas gracias Maria Angeles!
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/camilla-jane.jpg" alt="Camilla Jane profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Camilla Jane</div>
                      <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02JX9SGAKoGfas88ZBGmzFeQwY4GPqmuRX4LY1aLydueq9eVroqgVWNRA2e6T9Yyejl&id=621667059" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    We bought our secondary school daughter lessons as a Christmas gift as she had it on her list.
                    Maria at MySpanishNow has provided a real ‘level up’ experience and helped our daughter take amazing strides forward with learning Spanish but also in developing her love for learning languages.
                    Highly recommended!
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/alan-scott.jpg" alt="Alan Scott profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Alan Scott</div>
                      <a href="https://www.facebook.com/alan.scott.71653/posts/pfbid02f6p6KKaUMDP6Ym81beeJPFh2ruex5U9kvCkReJhPqff3WFLuWmGGXuRosJqx2CPUl" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    Muchas Gracias Maria Angeles - have learnt more in 3 sessions than in all my previous attempts to learn spanish 🙂
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/diane-cameron.jpg" alt="Diane Cameron profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Diane Cameron</div>
                      <a href="https://www.facebook.com/diane.cameron.313/posts/5313450718672636" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    Maria makes learning Spanish fun.  The time just passes so quickly when we have our one to one lessons. Maria is innovative in her approach and actually has taught me much more in a few weeks than I expected.
                    Enjoying learning with her.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/shirley-mcmeeken.jpg" alt="Shirley Mcmeeken profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Shirley Mcmeeken</div>
                      <a href="https://www.facebook.com/shirley.mcmeeken/posts/4526098224142986" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    I love my Spanish lessons with Maria. She is a patient and enthusiastic teacher who allows her students to work at a pace that is best for them. She makes the lessons fun - providing a great variety of resources to help practice each week. The games are great and help boost my confidence. I would highly recommend her one-to-one tuition for lessons that are tailor-made to your requirements. 😁👍
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/dot-whitelaw.jpg" alt="Dot Whitelaw profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Dot Whitelaw</div>
                      <a href="https://www.facebook.com/dot.whitelaw.7/posts/10158174473306688" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    I have loved my lessons with Maria. She is a very patient teacher, who has a lot of knowledge and enthusiasm for teaching. She structures the lessons around our needs, and is very adaptable. I would highly recommend her lessons. We started off with face to face lessons, but due to Covid-19, these are now online-and the quality of the lesson has remained to the same standards as face to face lessons. Maria also provides plenty homework on request, which is really helpful for when learning a new language!
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/becky-shovlin.jpg" alt="Becky Shovlin profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Becky Shovlin</div>
                      <a href="https://www.facebook.com/rshovlin/posts/10164389702730548" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    Great teacher with really interesting activities that help me use my Spanish while having fun.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/lucy-hawkins.jpg" alt="Lucy Hawkins profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Lucy Hawkins</div>
                      <a href="https://www.facebook.com/lucy.hawkins.102/posts/10158514058485469" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    I love my Spanish lessons with Maria Ángeles and look forward to them. At 52 years old it feels good to get my brain working again. She is always thinking of ways to make the lessons interesting and fun and I can't wait to try out my new skills on my next trip to Barcelona. If you're interested in learning Spanish I highly recommend MySpanishNow
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/pat-gibson.jpg" alt="Pat Gibson profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Pat Gibson</div>
                      <a href="https://www.facebook.com/pat.hanson.14/posts/4118002704882641" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div>
              <blockquote class="relative noselect">
                <div class="quote text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    I highly recommend! 👍🏼 The one-to-one lessons are a really good way of improving my level of Spanish, but also my confidence to speak. Maria Angeles is a great teacher, she adapts the level to my needs and make it funny. With her, learning becomes a pleasure 😊
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex">
                    <div class="flex-shrink-0 lg:hidden">
                      <img src="/images/reviews/charlene-robineau.jpg" alt="Charlène Robineau profile photo" class="rounded-circle">
                    </div>
                    <div class="author">
                      <div class="name">Charlène Robineau</div>
                      <a href="https://www.facebook.com/charlene.robineau/posts/10223092791002811" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </vue-tiny-slider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';

export default {
  name: 'Reviews',
  components: {
    VueTinySlider,
  },
  data() {
    return {
      responsive: {
        1: {
          items: 1,
          center: false,
        },
        992: {
          items: 2,
          center: false,
        },
        1291: {
          items: 2,
          center: false,
        },
      },
      controlsText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" stroke="none" fill="currentColor"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" stroke="none" fill="currentColor"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>'
      ],
    };
  },
};
</script>

<style scoped>
  header.major {
    margin: 0;
  }

  blockquote {
    border: none;
    padding: 0;
    padding-right: 35px;
  }

  blockquote .quote {
    position: relative;
    padding: 30px 30px;
  }

  blockquote .quote p {
    margin: 0;
    text-align: left;
  }

  blockquote .quote:before {
    font-family: 'FontAwesome';
    content: "\f10d";
    position: absolute;
    font-size: 35px;
    opacity: 0.1;
    font-style: normal;
    top: 1px;
    left: 0px;
  }

  blockquote .quote:after {
    font-family: 'FontAwesome';
    content: "\f10e";
    position: absolute;
    font-size: 35px;
    opacity: 0.1;
    font-style: normal;
    bottom: 0;
    right: 0;
  }

  blockquote .author {
    margin-left: 10px;
    text-align: left;
  }

  blockquote .author .name {
    top: -5px;
    position: relative;
    font-style: normal;
  }

  img {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 20px;
    color: #0056b3;
    position: relative;
    top: -15px;
  }
  .flex {
    display: flex;
  }

  .flex-shrink-0 {
    flex-shrink: 0;
    display: inline-flex;
  }

  @media screen and (max-width: 767px) {
    .reviews .review-container {
      margin-top: 25px;
    }

    .reviews .review-container:first-child {
      margin-top: 0;
    }
  }
</style>

<style>
  .reviews .tns-controls button {
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
    padding: 0px 10px;
  }

  .reviews .tns-controls button[data-controls="prev"] {
    left: -30px;
  }

  .reviews .tns-controls button[data-controls="next"] {
    right: -65px; /* 35px (width) + 30px */
  }

  .reviews .tns-controls button:hover svg {
    color: #3e3c45;
  }

  .reviews .tns-controls button svg {
    height: 30px;
    color: #323037;
  }

  @media screen and (max-width: 1100px) {
    .reviews .tns-controls button {
      position: static;
      margin-bottom: 15px;
    }

    .reviews .tns-controls button[data-controls="prev"] {
      margin-right: 25px;
    }

    .reviews .tns-controls button[data-controls="next"] {
      margin-left: 25px;
    }
  }
</style>
