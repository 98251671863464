<template>
  <section class="how-it-works wrapper style2 special">
    <div class="container">
      <header class="major">
        <h2>How it Works</h2>
        <!-- <p>Maecenas vitae tellus feugiat eleifend</p> -->
      </header>
      <div class="row">
        <div class="col-md step">
          <router-link :to="{ name: 'Register' }" target="_blank">
            <div class="icon">
              <i class="fas fa-sign-in-alt"></i>
            </div>
            <h3>Register</h3>
          </router-link>
        </div>
        <div class="col-md step">
          <router-link :to="{ name: 'Schedule lesson' }" target="_blank">
            <div class="icon">
              <i class="far fa-hand-pointer"></i>
            </div>
            <h3>Schedule your (free) lesson</h3>
          </router-link>
        </div>
        <div class="col-md step">
          <router-link :to="{ name: 'Schedule lesson' }" target="_blank">
            <div class="icon">
              <i class="far fa-calendar-alt"></i>
            </div>
            <h3>Pick a time and date</h3>
          </router-link>
        </div>
        <div class="col-md step">
          <router-link :to="{ name: 'Dashboard' }" target="_blank">
            <div class="icon">
              <i class="far fa-smile"></i>
            </div>
            <h3>Learn Spanish</h3>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowItWorks',
  props: {},
};
</script>

<style scoped>
  h3 {
    margin: 0;
  }

  .step .icon {
    font-size: 45px;
    width: fit-content;
    border-radius: 50%;
    box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.15);
    width: fit-content;
    padding: 35px;
    margin: auto;
    margin-bottom: 15px;
  }

  .step .icon:after {
    background-color: #f44066;
    content: "";
    display: inline-block;
    height: 3px;
    vertical-align: middle;
    width: 170px;
    position: absolute;
    left: 100%;
    top: 57px;
  }

  .step:last-child .icon:after {
    content: none;
  }

  .step .icon:hover {
    box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.25);
  }

  .step .icon i {
    width: 45px;
    color: #f44066;
  }

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 1280px) {
    .step .icon:after {
      width: 121px;
    }
  }

  @media screen and (max-width: 991px) {
    .step .icon:after {
      width: 63px;
    }
  }

  @media screen and (max-width: 796px) {
    .step .icon:after {
      content: none;
    }
  }

  @media screen and (max-width: 767px) {
    .step {
      margin-top: 25px;
    }

    .step:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 736px) {
    header {
      margin-bottom: 0;
    }
  }
</style>
