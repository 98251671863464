import { render, staticRenderFns } from "./TeachingMethod.vue?vue&type=template&id=073a470a&scoped=true&"
import script from "./TeachingMethod.vue?vue&type=script&lang=js&"
export * from "./TeachingMethod.vue?vue&type=script&lang=js&"
import style0 from "./TeachingMethod.vue?vue&type=style&index=0&id=073a470a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073a470a",
  null
  
)

export default component.exports