<template>
  <div class="home page">
    <banner></banner>
    <is-it-for-me></is-it-for-me>
    <reviews></reviews>
    <teaching-method></teaching-method>
    <how-it-works></how-it-works>
    <plans></plans>
    <contact-me></contact-me>
  </div>
</template>

<script>
import Banner from '../../components/common/homepage/Banner.vue';
import IsItForMe from '../../components/common/homepage/IsItForMe.vue';
import TeachingMethod from '../../components/common/homepage/TeachingMethod.vue';
import HowItWorks from '../../components/common/homepage/HowItWorks.vue';
import Plans from '../../components/common/homepage/Plans.vue';
import ContactMe from '../../components/common/homepage/ContactMe.vue';
import Reviews from '../../components/common/homepage/Reviews.vue';

export default {
  name: 'Home',
  props: {},
  components: {
    Banner,
    IsItForMe,
    TeachingMethod,
    HowItWorks,
    Plans,
    ContactMe,
    Reviews,
  },
};
</script>
