<template>
  <section id="two" class="wrapper style1 special">
    <div class="container">
      <header class="major">
        <h2>Teaching Method</h2>
        <!-- <p>Maecenas vitae tellus feugiat eleifend</p> -->
      </header>
      <div class="row align-left">
        <div class="col-lg">
          <h3>Communicative</h3>
          <p>Develop your oral skills and feel confident to speak and understand native speakers</p>
        </div>
        <div class="col-lg">
          <h3>Flexible</h3>
          <p>Pick the time, choose the place and learn SpanishNow</p>
        </div>
        <div class="col-lg">
          <h3>Personalised</h3>
          <p>Teaching adapted to your needs, interests and goals</p>
        </div>
      </div>
      <ul class="actions">
        <li><router-link :to="{ name: 'Register'}" target="_blank"
          class="button special big">Register and get a Free Lesson</router-link></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionTwo',
  props: {},
};
</script>

<style scoped>
  @media screen and (max-width: 980px) {
    .actions {
      margin: 0;
    }
  }

  @media screen and (min-width: 736px) {
    header {
      margin-bottom: 0;
    }
  }
</style>
